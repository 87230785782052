import $ from 'jquery';
import 'slick-carousel';

/*

Main javascript functions to init most of the elements

#1. TESTIMONIALS
#2. PROJECTS SLIDER

*/

$(document).on('turbolinks:load', function() {

  // this is to open the modal for a contribution when user clicks a notification for a contribution, we need to redirect to the document page and open relevant modal
  let contribution_id = $('*[data-open-modal-for][data-open-modal-for!=""]').attr('data-open-modal-for');
  $('a.btn.open-modally[href="/account/contributions/' + contribution_id + '"]').click();

  // #1. TESTIMONIALS
  $('.testimonials-slider').slick({
    infinite: true,
    variableWidth: true,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false
      }
    }]
  });

  // #2. PROJECTS SLIDER
  $('.projects-slider-i').slick({
    infinite: true,
    variableWidth: true,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false
      }
    }]
  });

});
