$(document).on('turbolinks:load', function() {
  function cardSelector(cardId) {
    return '.card[data-id=' + cardId +'] .open-modally div';
  }

  $('.messages-notifications').on('click', '.message-notification[data-url]', function(e) {
    // If the A is clicked just load it normally.
    if ($(e.target).prop('tagName') == 'A') {
      return;
    }
    let dataUrl = $(this).data('url');
    if (dataUrl.match(/\/account\/contributions\/(\d+)/)[1]) {
      //redirect to the contribution page
      Turbolinks.visit(dataUrl);
      return;
    }

    var selector= '.page-conversation a';
    var cardId = $(this).data('card-id');
    if (cardId) {
      selector = cardSelector(cardId);
    }

    e.stopPropagation();
    Turbolinks.visit(dataUrl);
    document.addEventListener('turbolinks:load', clickWhenLoaded(selector), {once: true})
  });

  $('.messages-notifications').on('click', '.card-notification[data-url]', function(e) {
    //If the A is clicked just load it normally.
    if ($(e.target).prop('tagName') == 'A') {
      return;
    }

    var cardId = $(this).data('card-id');
    e.stopPropagation();
    var url = $(e.target).closest('[data-url]').data('url');

    var selector = cardSelector(cardId);

    if (url == window.location.pathname) {
      $(selector).trigger('click');
    }
    else {
      Turbolinks.visit(url);
    }
  });

  $('.messages-notifications').on('click', '.customer-account-team-notification[data-url]', function(e) {
    if ($(e.target).prop('tagName') == 'A') {
      return;
    }

    var url = $(e.target).closest('[data-url]').data('url');
    Turbolinks.visit(url);
  });

  function clickWhenLoaded(selector, tries) {
    console.log('clickWhenLoaded')
    console.log('selector', selector)
    setTimeout(function() {
      if (!tries) {
        tries = 1;
      }

      if ($(selector).length) {
        $(selector).trigger('click');
      }
      else if (tries < 20) {
        clickWhenLoaded(selector, tries + 1)
      }
    }, 100);
  }
});
