$(document).on('turbolinks:load', function() {
  $('#opportunities-date').change(function() {
    if ($(this).find('select').val()) {
      Turbolinks.visit($(this).data('current-path') + '?date_select=' + $(this).find('select').val())
    }
    else {
      Turbolinks.visit($(this).data('current-path'))
    }
  })

  if ($("[data-globalise-as='$opportunitiesTable']").length) {
    var interval = setInterval(function() {
      if (window['$opportunitiesTable']) {
        $opportunitiesTable.on('draw', function() {
          renderTotal()
        })

        renderTotal()
        clearInterval(interval)
      }
    }, 5)
  }

  function renderTotal() {
    var total = 0
    $.each($opportunitiesTable.rows({ 'search': 'applied' }).nodes(), function(idx, node) {
      if ($(node).data('value')) {
        total += parseInt($(node).data('value'))
      }
    })
    $('#opportunities-total').html(total.toLocaleString())
  }
})
