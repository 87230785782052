$(document).on('turbolinks:load', function() {
  $('.ajax-tab').click(function() {
    var url = $(this).data('source') + "&target_id=" + $(this).attr('href').replace('#', '');

    if ($(this).data('param')) {
      url += "&param=" + encodeURI($($(this).data('param')).val());
    }

    $.getScript(url)
  })
});


