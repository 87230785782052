require("./plans")
require("./progress")
require("./fields")
require("./conversations/subscriptions")
require("./notifications")
require("./prospects")
require("./opportunities")
require("./opportunities_table")
require("./line_items")
require("./decision_making_group")
require("./datatables")
require("./mobile")
require("./ajax_tabs")
require("./timelines")
require("./attachments")
require("./contributions")

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
