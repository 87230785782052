$(document).on('turbolinks:load', function() {
   $('body').on('change', '#opportunity_win_loss_status', function() {
    if ($(this).val() == 'Won') {
      $('.actual-close-date').removeClass('hidden');
    }
    else {
      $('.actual-close-date').addClass('hidden');
    }

    if ($(this).val() == 'Open' || $(this).val() == 'No decision' ) {
      $('.closed-opportunity').addClass('hidden')
    }
    else {
      $('.closed-opportunity').removeClass('hidden')
    }
  })

  $('#opportunity_win_loss_status').trigger('change');
});

$(document).on('turbolinks:load', function() {
  $('body').on('click', '.forecast-wrapper .dropdown-item', function(e){
    e.preventDefault()
    var btnObj = $(this).parent().siblings('button');
    $(btnObj).text($(this).text());
    $(btnObj).val($(this).text());

    if ($('.forecast-wrapper .current-value').text().trim() == $(this).text().trim()) {
      $('.forecast-wrapper .open-modally').addClass('faded');
    }
    else {
      $('.forecast-wrapper .open-modally').removeClass('faded');
    }

    $('[data-toggle=dropdown]').dropdown('toggle')
  });

  $('body').on('click', '.forecast-wrapper .faded', function() {
    return false;
  });
});

$(document).on('turbolinks:load sprinkles:update', function(e) {
  function snap($el) {
    var value = $el.bootstrapSlider('getValue');
    if (value / 100 !== parseInt(value / 100)) {
      $el.bootstrapSlider('setValue', Math.floor(value / 100) * 100, true, true);
    }
  }

  $('.status-slider').each(function(slider) {
    var sliderId = $(this).data('slider-id');
    if (!$('#' + sliderId).length) {
      var $that = $(this);
      var initialValue = $that.val();
      var $modalLink = $that.parent().find('.status-slider-link');

      if ($that.data('trigger-expert-help')) {
        // If expert help is already open, close it first.
        $('.popover').popover('hide');
        setTimeout(function() {
          $('.expert-help').trigger('click');
        }, 500);
      }

      $that.bootstrapSlider().on("slide", function() {
        // blank for now
      })
      .on("slideStop", function() {
        snap($that);
        var sliderVal = $that.bootstrapSlider('getValue') / 100;
        if (sliderVal != initialValue) {
          setTimeout(function() {
            $modalLink.attr("href", $modalLink.attr("href") + "?new_status=" + sliderVal);
            $modalLink.trigger('click');
          }, 25)
        }
      });
    }
  });

  $('.opportunity-strategy-slider').each(function(slider) {
    var sliderId = $(this).data('slider-id');
    if (!$('#' + sliderId).length) {
      var $that = $(this);

      $that.bootstrapSlider();
      // Make it a slider, but disable it as it is only for display purposes.
      $that.bootstrapSlider('disable');
    }
  });
});

$(document).on('turbolinks:load', function() {
  var opportunityBoardIds = $('.opportunity-strategy-slider').data('board-ids');
  $('body').on('click', '.opportunity-strategy-wrapper .slider-tick, .opportunity-strategy-wrapper .slider-handle', function(e) {
    var phase, $target = $(e.target);

    if ($target.hasClass('slider-tick')) {
      phase = $('.opportunity-strategy-wrapper').find('.slider-tick').index($(e.target));
    }
    else {
      phase = $('.opportunity-strategy-slider').val() / 100;
    }

    var boardId = opportunityBoardIds[phase];
    var isLastPhase = phase >= $('.opportunity-strategy-wrapper').find('.slider-tick').length - 1;

    if (boardId) {
      $('.popover').remove();

      setTimeout(function() {
        var boardDetail = $('.board-detail[data-board-id="' + opportunityBoardIds[phase] + '"]');

        var popoverSettings = {
          container: 'body',
          html: true,
          title: boardDetail.data('title'),
          placement: isLastPhase ? 'left' : 'top',
          body: boardDetail.html()
        }

        $target
          .popover(popoverSettings)
          .popover('show');

        $('.popover-body').html(boardDetail.html());
      }, 20)
    }
  });

  $('body').on('click', '.popover-header', function() {
    $('.popover').remove();
  });
});


