$(document).on('turbolinks:load', function() {
  var $breadCrumb = $('.breadcrumb-wrapper .breadcrumb');
  $breadCrumb.width($('.breadcrumb-wrapper').width());
  $breadCrumb.scrollLeft($breadCrumb.width());
});

$(window).on('resize', function() {
  var $breadCrumb = $('.breadcrumb-wrapper .breadcrumb');
  $breadCrumb.width($('.breadcrumb-wrapper').width());
  $breadCrumb.scrollLeft($breadCrumb.width());
});
