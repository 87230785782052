var moment = require('moment');

$(document).on('turbolinks:load triggerDataTables', function() {
  $.fn.dataTable.ext.search = [];

  var entriesName;

  if ($('.datatable-enabled').data('entries-name')) {
    entriesName = $('.datatable-enabled').data('entries-name')
  }
  else {
    entriesName = "entries"
  }

  var $table = $('.datatable-enabled').DataTable({
    "lengthChange": false,
    "ordering": true,
    "fnDrawCallback": function (oSettings) {
      var pgr = $(oSettings.nTableWrapper).find('.dataTables_paginate')
      if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
        pgr.hide();
      } else {
        pgr.show()
      }
    },
    "language": {
      "info": "Showing _START_ to _END_ of _TOTAL_ " + entriesName,
      "infoEmpty": "Showing 0 to 0 of 0 " + entriesName,
      "emptyTable": "No results found",
    }
  });
  // See https://datatables.net/reference/option/language

  if ($('.datatable-enabled').data('default-sort')) {
    var sort = 'desc'
    if ($('.datatable-enabled').data('default-sort-order')) {
      sort = $('.datatable-enabled').data('default-sort-order')
    }

    $table
      .order( [ $('.datatable-enabled').data('default-sort'), sort ] )
      .draw();
  }

  if ($('.datatable-enabled').data('globalise-as')) {
    window[$('.datatable-enabled').data('globalise-as')] = $table;
  }

  $('[data-numeric-filter-col]').each(function() {
    var that = this;
    $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
        var selectedValue = $(that).val();
        var filterColumn = $(that).data('numeric-filter-col');
        var min, max, columnValue = parseFloat( data[parseInt(filterColumn)] );

        if (selectedValue === '') {
          return true;
        }

        if (columnValue === '') {
          return false;
        }

        if (selectedValue.match('-')) {
          max = parseInt( selectedValue.split('-')[1] );
          min = parseInt( selectedValue.split('-')[0] );
        }
        else {
          max = parseInt( selectedValue );
          min = parseInt( selectedValue );
        }

        return columnValue >= min && columnValue <= max;
      }
    );
  });

  $('[data-date-filter-col]').each(function() {
    var that = this;
    $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
        var selectedValue = $(that).val();
        var filterColumn = $(that).data('date-filter-col');
        var min, max, columnValue, columnValueAsDate;
        var columnValue = data[parseInt(filterColumn)]

        if (selectedValue === '') {
          return true;
        }

        if (columnValue === '') {
          return false;
        }

        columnValueAsDate = moment.utc(columnValue, "DD MMM YYYY");

        if (selectedValue.match('-')) {
          max = moment.utc( selectedValue.split('-')[1] );
          min = moment.utc( selectedValue.split('-')[0] );
        }
        else {
          max = moment.utc( selectedValue );
          min = moment.utc( selectedValue );
        }

        return columnValueAsDate >= min && columnValueAsDate <= max;
      }
    );
  });

  $('[data-array-filter-col]').each(function() {
    var that = this;
    $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
        var selectedValue = $(that).val();
        var filterColumn = $(that).data('array-filter-col');
        var columnValue = data[parseInt(filterColumn)];
        var matches = true;

        selectedValue.forEach(function(item) {
          if (!columnValue.includes("|" + item.trim() + "|")) {
            matches = false;
          }
        });

        return matches;
      }
    );
  });


  function filter() {
    $('[data-filter-col]').each(function(idx, item) {
      var val = $(item).val();
      if (val == '--any--') {
        val = "";
      }

      $table.columns([$(item).data('filter-col')])
        .search(val);
    });

    $('[data-search]').each(function(idx, item) {
      var val = $(item).val();
      if (val == '--any--') {
        val = "";
      }

      $table.search(val);
    });

    $table.draw();
    // Re-enable tooltips.
    $('[data-toggle="tooltip"]').tooltip();

  }

  $('body').on('change keyup', '[data-search], [data-filter-col], [data-numeric-filter-col], [data-date-filter-col], [data-array-filter-col]', filter);
});

$(document).on('sprinkles:update', function () {
    $('.datatable-enabled').DataTable().draw()
})