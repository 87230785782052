$(document).on('turbolinks:load', function() {

  $('body').on('submit', '.decision-making-group-form', function() {
    $('.decision-making-group-member-form').each(function(idx, el) {
      if ($(el).find('select').val().length == 0) {
        $(el).find("input[name*='_destroy']").val(1);
      }
    });

    $('.decision-making-group-member-template').remove();
  });

  // Remove an item and update the _destroy field to remove it server side.
  $('body').on('click', '[data-action=click-remove-decision-making-group-member]', function(e) {
    e.preventDefault();
    var $item = $(e.target).parents('.decision-making-group-member-form');
    $item.find("input[name*='_destroy']").val(1);
    $item.hide();
  });

  // Add an item to a member type.
  $('body').on('click', '[data-action=click-add-decision-making-group-member]', function(e) {
    e.preventDefault();
    var $container = $(e.target).parents('.decision-making-group-member-type');

    var $content = $($container.find('.decision-making-group-member-template').html().replace(/TEMPLATE_RECORD/g, new Date().valueOf()));

    // Remove select 2 remnants.
    $content.find('.select2-container').remove();
    $content.find('select').each(function(idx, el) {
      $(el).removeClass('select2-hidden-accessible').removeAttr('data-select2-id').attr('id', Math.floor(Math.random() * 9999999));
    });

    // Remove options that are already selected for convenience
    var selectedContactIds = [];
    $container.find('select.contact:visible').each(function(idx, el) {
      if ($(el).val() != "") {
        selectedContactIds.push($(el).val());
      }
    });
    $content.find('select.contact option').each(function(idx, el) {
      if (selectedContactIds.indexOf($(el).val()) !== -1) {
        $(el).remove()
      }
    });

    $(e.target).parents('.add-member-wrapper').before($content);

    // Re-enable select2.
    $('.select2').select2();
  });
});
