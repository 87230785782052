$(document).on('turbolinks:load', function() {
  // Add a row for a new line item.
  $('body').on('click', '[data-action=click-add-association]', function(e) {
    e.preventDefault();
    var content = "<tr" + $('.line-item-template').html().replace(/TEMPLATE_RECORD/g, new Date().valueOf()) + "</tr>";
    $('table.quote-details tbody').append(content)
  });

  // Remove a line item and update the _destroy field to remove it server side.
  $('body').on('click', '[data-action=click-remove-association]', function(e) {
    e.preventDefault();
    var $item = $(e.target).parents("tr");
    $item.find("input[name*='_destroy']").val(1);
    $item.hide();
    $item.find('.line-item-value').trigger('change');
  });

  // Remove new item template before submitting to avoid confusing the back end.
  $('body').on('submit', '.opportunity-form', function() {
    $('.line-item-template').remove();
  });

  // Show and update total value of line items.
  $('body').on('keyup change', '.line-item-value', function(e) {
    e.preventDefault();
    var val = 0;
    $('.line-item-value:visible').each(function() {
      if ($(this).val() > 0) {
         val += parseFloat($(this).val())
      }
    });
    // Format with commmas.
    val = val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    $('.total .total-amount').html(val);

    if ($('.line-item-value:visible').length) {
      $('.total-label, .total').show();
    }
    else {
      $('.total-label, .total').hide();
    }
  });

  $('.line-item-value').trigger('change');
});
