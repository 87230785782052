$(document).on('turbolinks:load', function () {
    const targetNode = document.querySelector('#contributions-container');
    const documentContainer = document.querySelector('#document-content-container');
    if (!targetNode || !documentContainer) {
        return;
    }
    const originalClasses = documentContainer.className;
    const callback = function (mutationsList, observer) {
        for (const mutation of mutationsList) {
            if (mutation.type !== 'childList') {
                continue;
            }
            if (targetNode.children.length > 0) {
                documentContainer.className = 'col-md-6';
            } else {
                documentContainer.className = originalClasses;
            }
        }
    };

    const observer = new MutationObserver(callback);

    observer.observe(targetNode, {childList: true});
});

