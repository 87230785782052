window.timelineAdvanceToMonth = function(selector, date_string) {
  // Add an event, move to it then remove it.
  var eventId = '999999998';
  var targetDate = new Date(date_string);
  var timelineStart= $(selector).data()['jq.timeline']._instanceProps.begin;

  if (targetDate.getTime() < timelineStart) {
    targetDate = new Date(timelineStart);
  }

  $(selector).Timeline('addEvent', [
    {id: parseInt(eventId), start: targetDate, row:1}
  ]);

  $(selector).Timeline('alignment', eventId, 'slow');

  $(selector).Timeline('removeEvent', [
    eventId
  ]);

  $('[data-toggle="popover"]').popover();
}

