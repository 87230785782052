$(document).on('turbolinks:load', function() {
  $('.prospect-status-wrapper .dropdown-item').on('click',  function(e){
    e.preventDefault()
    var btnObj = $(this).parent().siblings('button');
    $(btnObj).text($(this).text());
    $(btnObj).val($(this).text());

    $('[data-toggle=dropdown]').dropdown('toggle')
  });
});


